<template>
  <div class="content">
    <div class="toptitle">
      <span class="size36">企业责任</span>
      <p>Responsibility</p>
    </div>
    <div class="main">
      <div class="mleft" @click="touch(1)" id="hei">
        <div class="title1">免费服务 致敬抗疫一线医疗工作者</div>
        <div class="text1">
          新冠疫情爆发后，为向广大抗疫一线工作者致敬，公司实施合肥市抗疫一线工作者免费预约出行服务政策，2020年2月11日起，合肥市一线的医疗工作者、政府机构工作人员、社区工作者均可申请并享受免费出行服务。
        </div>
        <img src="../../../static/images/about/1.png" alt="" />
      </div>
      <div
        class="mright"
        @onmouseout="leave()"
        @onmouseover="over()"
        :style="{ color: color }"
      >
        <div class="right" @click="touch(2)">
          <div class="title1">共克时艰 捐款助力疫情防控</div>
          <p class="text1">
            “一方有难，八方支援”，面对突如其来的新冠疫情，和行公司高度重视，向广大党员干部职工发出为疫区捐款奉献爱心的倡议。
          </p>
        </div>
        <div class="box"></div>
        <div class="right" @click="touch(3)">
          <div class="title1">人车防护 力保司乘安全</div>
          <p class="text1">
            新冠疫情爆发后，公司为所有运营网约车、出租车、租赁车辆提供免费消毒服务和医用口罩，其中消毒服务对全市所有社会车辆同步免费提供。
          </p>
        </div>
        <div class="box"></div>
        <div class="right" @click="touch(4)">
          <div class="title1">弘扬正气 好人好事不断</div>
          <p class="text1">
            2021期间，公司共涌现各类好人好事1189起，为乘客挽回经济损失约43万元。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      color: "",
    };
  },
  methods: {
    touch(index) {
      switch (index) {
        case 1:
          this.$router.push({ name: "slect1" });
          break;
        case 2:
          this.$router.push({ name: "slect2" });
          break;
        case 3:
          this.$router.push({ name: "slect3" });
          break;
        case 4:
          this.$router.push({ name: "slect4" });
          break;
      }
    },
    leave() {},
    over() {},
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  font-weight: 400;
  color: #2d2d39;
  .toptitle {
    box-sizing: border-box;
    padding: 4% 0 0 4%;
    .size36 {
      font-size: 36px;
    }
    p {
      margin-left: 1px;
      font-size: 16px;
    }
  }
  .main {
    width: 72%;
    margin: 90px auto 0;
    display: flex;
    .mleft {
      cursor: pointer;
      width: 45.8%;
      margin-right: 8%;
      border-top: 2px solid #e53e3e;
      .title1 {
        font-size: 16px;
        color: #e53e3e;
        font-weight: 400;
        margin: 38px 0 0 1px;
      }
      .text1 {
        line-height: 25px;
        margin: 35px 0 0 1px;
        font-size: 14px;
        // 两行之后超出部分隐藏 显示。。。
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      img {
        width: 100%;
        margin-top: 30px;
      }
    }
    .mright {
      cursor: pointer;
      width: 46%;
      .right {
        width: 100%;
        height: 33%;
        padding-top: 10%;
        box-sizing: border-box;
        .title1 {
          font-size: 16px;
          transition: all .7s;
        }
        .text1 {
          margin-top: 5%;
          line-height: 25px;
          font-size: 14px;
          display: none;
          transition: all .7s linear;
        }
      }
      .right:hover .title1 {
        margin-top: -5%;
        line-height: 0;
        color: #e53e3e;
      }
      .right:hover .text1 {
        animation: fadei  1.5s   ease-in-out;
        display: block;
      }
      .box {
        width: 100%; 
        height: 1px;
        background-color: #e0e0e0;
      }
      @keyframes fadei {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
</style>